import React from 'react';
import { BannerFree } from './BannerFree'
import  Booking from './Booking'
import CodePenCollection from './CodePenCollection';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography, Button, Box, Container } from '@mui/material';

const FreelanceCoaching = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {/* Hero Section */}
      <Box
        sx={{
          marginTop: '15vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/hero-image.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <BannerFree/>
        {/* <Typography variant="h2" sx={{ fontWeight: 'bold' }}>J'AIDE LES FREELANCES À</Typography> */}
        <Typography variant={isSmallScreen ? "h7" : "h5"} sx={{ mb: 2 }}>
          Votre chemin vers le succès du freelance
        </Typography>
        <Button variant="contained" sx={{ backgroundImage: 'linear-gradient(90.21deg, #4A2FBD -5.91%, #AA367C 111.58%)'}}>En savoir plus</Button>
      </Box>

      {/* Main Content (You can add more sections here) */}
      <Container component="main" sx={{ flexGrow: 1, py: 3 }}>
        <Booking/>
        <Typography variant="h4" gutterBottom>
          Nos Services
        </Typography>
        <Typography variant="body1">
          Nous offrons une gamme de services pour aider les freelances à réussir dans leurs carrières.
        </Typography>
        <CodePenCollection/>
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundImage: 'linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%)', p: 3, mt: 'auto' }}>
        <Container maxWidth="lg">
          <Typography variant="body1" color="inherit">
            &copy; 2024 Kohl Mathieu Freelance Coach. Tous droits réservés.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default FreelanceCoaching;
