import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography, Container } from '@mui/material';
import 'animate.css';

export const BannerFree = () => {

    const [loopNum, setLooNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = [ "OBTENIR D'AVANTAGE DE CLIENTS",  "AUGMENTER LEURS PRIX"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(100 - Math.random() * 100);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // eslint-disable-next-line no-unused-vars
    const [index, setIndex] = useState(1);
    const period = 1000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);
        
        return () => { clearInterval(ticker) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting){
            setDelta(prevDelta => prevDelta / 2)
        }

        if (!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setIndex(prevIndex => prevIndex - 1);
            setDelta(period);
        } else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLooNum(loopNum + 1);
            setIndex(1);
            setDelta(500);
        } else {
            setIndex(prevIndex => prevIndex + 1);
        }
    }


    return (
        <section className="bannerFree">
            <Container>
                <Row className="align-items-center">
                        <div className={"animate__animated animate__fadeIn"}>
                            {/* <h2>{t('greeting')}<span className="wrap">{text}</span></h2> */}
                            <Typography variant={isSmallScreen ? "h4" : "h2"} sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '1.5rem' : '3rem' }}>J'AIDE LES FREELANCERS À <br/> {text}</Typography>
                        </div>
                </Row>
            </Container>
        </section>
    )
}