import projImg1 from "../../assets/img/fridge.jpg";
import projImg2 from "../../assets/img/shopify.png";

export const appProjects = [
    {
        title: "Coolio",
        description: "App that help organize your fridge, and usie OpenAi to suggest recipes",
        description2: "Development in progress",
        linkUrl: "https://github.com/mathieukohl/fridge_manager",
        imgUrl: projImg1,
    },
    {
        title: "CGV Generator",
        description: "App that generate shopify CGV",
        description2: "Only in french at the moment",
        linkUrl: "https://kohlmathieu.com/App/CGV_App",
        imgUrl: projImg2,
    }
];
