import React from 'react';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { NavBarPage } from "./components/NavBarPage";
import { NavBarFreelance } from "./components/NavBarFreelance";
import { Banner } from "./components/Banner";
import { Skills } from './components/Skills';
// import { HorizontalScroll } from './components/HorizontalScroll';
import { Projects } from './components/Projects';
import { Articles } from './components/Articles';
import { Services } from './components/Services';
import { About } from './components/About';
import { Footer } from './components/Footer';
import { FiverrBadge } from './components/FiverrBadge';
import { Route, Routes, Outlet } from 'react-router-dom';
import NotFoundPage from './components/NotFoundPage'; 

import More from './components/pages/More';
import CGV_APP from './components/pages/CGV_App';

// import UnderConstruction from './components/pages/UnderConstruction';
import { Helmet } from 'react-helmet';

import LearnHome from './components/pages/learn/LearnHome';
import FreelanceCoach from './components/pages/freelance/FreelanceCoach';
import FreeHome from './components/pages/freelance/FreeHome';
import FormFree from './components/pages/freelance/FreelancerForm';
//import ArticlesTemplate from './components/pages/learn/ArticlesTemplate'
import ArticlesGrid from './components/pages/learn/ArticlesGrid';
import BlogPostTemplate from './components/blog/BlogPostTemplate';
import article from './data/articles.json';

const App = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Mathieu - Développeur Web en Valais, Suisse</title>
        <meta name="description" content="Mathieu Kohl - Développeur Full Stack et WordPress expérimenté en Valais, Suisse. Améliorer votre présence sur le web." />
        <meta name="keywords" content="Développement Web, WordPress, Full Stack, React.js, Vue.js Valais, IT, Programmation, Suisse" />
      </Helmet>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="/App/CGV_App" element={<CGVPage />} />
          <Route path="freelanceCoaching" element={<FreelanceCoachPage />} />
          <Route path="more" element={<MorePage />} />
          <Route path="more/freeHome" element={<FreeHomePage />} />
          <Route path="more/freeHome/form" element={<FormFreePage />} />
          <Route path="more/learnHome" element={<LearnHomePage />} />
          <Route path="more/learnHome/reactjs" element={<ReactJSPage />} />
          <Route path="more/learnHome/reactjs/article/:articleId" element={<ArticlePage />} />
          <Route path="/article/:id" element={<ArticlePage articles={article} />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Fallback route */}
        </Route>
      </Routes>
    </div>
  );
};

const RootLayout = () => (
  <>
    <Outlet />
  </>
);

const Home = () => (
  <>
    <NavBar />  
    <Banner />
    <Skills />
    {/* <HorizontalScroll /> */}
    <Projects />
    {/* <Articles />*/}
    <FiverrBadge />
    <Services />
    <About />
    <Footer />
  </>
);

const CGVPage = () => (
  <>
    <NavBarPage />
    <CGV_APP />
  </>
);

const FreelanceCoachPage = () => (
  <>
    <NavBarPage />
    <FreelanceCoach />
  </>
);

const MorePage = () => (
  <>
    <NavBarPage />
    <More />
    <CGV_APP />
    {/* <UnderConstruction /> */}
  </>
);

const FreeHomePage = () => (
  <>
    <NavBarFreelance />
    <FreeHome />
  </>
);

const FormFreePage = () => (
  <>
    <NavBarFreelance />
    <FormFree />
  </>
);

const LearnHomePage = () => (
  <>
    <NavBarPage />
    <LearnHome />
  </>
);

const ReactJSPage = () => (
  <>
    <NavBarPage />
    <ArticlesGrid />
  </>
);

const ArticlePage = () => (
  <>
    <NavBarPage />
    <BlogPostTemplate />
  </>
);

export default App;

// function App() {


//   return (
//     <div className="App">
//       <Helmet>
//         <title>Mathieu - Développeur Web en Valais, Suisse</title>
//         <meta name="description" content="Mathieu Kohl - Développeur Full Stack et WordPress expérimenté en Valais, Suisse. Améliorer votre présence sur le web." />
//         <meta name="keywords" content="Développement Web, WordPress, Full Stack, React.js, Vue.js Valais, IT, Programmation, Suisse" />
//       </Helmet>
//       <Routes>
//         <Route path="/" element={<>
//           <NavBar /> 
//           <Banner />
//           <Skills />
//           <Projects />
//           <FiverrBadge />
//           <Services />
//           <About />
//           <Footer />
//         </>} />
//         <Route path="App/CGV_App" element={<>
//           <NavBarPage />
//            <CGV_APP/>
//         </>} />
//         <Route path="more" element={<>
//           <NavBarPage />
//            <More />
//            <CGV_APP/>
//            {/* <UnderConstruction/> */}
//         </>} />
//         <Route path="/more/freeHome" element={<>
//           <NavBarFreelance />
//           <FreeHome />
//         </>} />
//           <Route path="/more/freeHome/form" element={<>
//             <NavBarFreelance />
//             <FormFree />
//           </>} />
//         <Route path="/more/learnHome" element={<>
//           <NavBarPage />
//           <LearnHome />
//         </>} />
//         <Route path="/more/learnHome/reactjs" element={<>
//           <NavBarPage />
//           <ArticlesGrid/>
//         </>} />
//         <Route path="/more/learnHome/reactjs/article/:articleId" element={<>
//           <NavBarPage />
//           <ArticlesTemplate/>
//         </>} />
//         <Route path="*" element={<NotFoundPage />} /> {/* Fallback route */}
//       </Routes>
//     </div>
//   );
// }

// export default App;
