export const skills = [
    "JavaScript",
    "React.js",
    "Vue.js",
    "React Native",
    "C#",
    "Python",
    "Node Js",
    "Mongo DB",
    // ... other skills
  ];