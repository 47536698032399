import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import img1 from "../assets/img/wordpress.png";
import img2 from "../assets/img/webdev.jpeg";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; 

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
  })(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
	  duration: theme.transitions.duration.shortest,
	}),
  }));

export const Services = () => {

	const { t } = useTranslation();
	const [expanded1, setExpanded1] = React.useState(false);
	const [expanded2, setExpanded2] = React.useState(false);
	const [expanded3, setExpanded3] = React.useState(false);
	const [expanded4, setExpanded4] = React.useState(false);


	const handleExpandClick1 = () => {
		setExpanded1(!expanded1);
	};

	const handleExpandClick2 = () => {
		setExpanded2(!expanded2);
	};

	const handleExpandClick3 = () => {
		setExpanded3(!expanded3);
	};

	const handleExpandClick4 = () => {
		setExpanded4(!expanded4);
	};

	const handleButtonClick = () => {
		window.location.href = 'https://ive5xucvsom.typeform.com/to/K9eH4fI4';
	};

	const handleButtonWeb = () => {
		window.location.href = 'https://ive5xucvsom.typeform.com/to/Toiz1sjB';
	}

  return (
	<section id="services" style={{ backgroundColor: 'black',}}>
		<div className='service'><h1>{t('servicesHeader')}</h1></div>	
		<div className="services-container">
			{/* <h1 className="services-header">My Services</h1> */}
			<Card sx={{ width: { xs: '100%',sm: '45%' }, backgroundColor: '#1F2937', marginBottom: '2rem' }}>
				<CardHeader
				sx={{ color: '#fff' }}
					title="WordPress Development"
				/>
				<CardMedia
					sx={{minWidth: '350px', height: '250px', objectFit: 'cover'}}
					component="img"
					src={img1}        
					alt="WordPress Development"
				/>
				<CardActions disableSpacing>
					<Typography 
					expand={expanded1}
					onClick={handleExpandClick1}
					aria-expanded={expanded1} 
					sx={{ color: '#fff', marginLeft: '1rem', cursor: 'pointer' }} >
					{t('serviceDetails')}
					</Typography>
					<ExpandMore
					expand={expanded1}
					onClick={handleExpandClick1}
					aria-expanded={expanded1}
					aria-label="show more"
					sx={{ color: '#fff' }}
					>
					<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
				<Collapse in={expanded1} timeout="auto" unmountOnExit>
					<CardContent>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
							{t('wordpressDevelopment.description')}
						 </Typography>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
							{t('contactMe')}
						</Typography>
						<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
							<Button onClick={handleButtonClick} variant="outlined">{t('getInvoice')}</Button>
						</div>
						<Typography sx={{ color: '#fff', marginBottom: '1rem' }} variant="h4">{t('serviceDetails')} :</Typography>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
							{t('wordpressDevelopment.desc2')}
						</Typography>
						<ul style={{color: '#fff'}}>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.customDesignT')}</strong> {t('wordpressDevelopment.serviceDetailsList.customDesign')}</li>
							<br/>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.eCommerceSolutionsT')}</strong> {t('wordpressDevelopment.serviceDetailsList.eCommerceSolutions')}</li>
							<br/>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.seoOptimizationT')}</strong> {t('wordpressDevelopment.serviceDetailsList.seoOptimization')}</li>
							<br/>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.mobileOptimizationT')}</strong> {t('wordpressDevelopment.serviceDetailsList.mobileOptimization')}</li>
							<br/>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.performanceEnhancementsT')}</strong> {t('wordpressDevelopment.serviceDetailsList.performanceEnhancements')}</li>
							<br/>
							<li><strong>{t('wordpressDevelopment.serviceDetailsList.ongoingSupportT')}</strong> {t('wordpressDevelopment.serviceDetailsList.ongoingSupport')}.</li>
						</ul>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
							{t('wordpressDevelopment.ready')}
						</Typography>
						<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
							<Button onClick={handleButtonClick} variant="outlined">{t('getInvoice')}</Button>
						</div>
					</CardContent>
				</Collapse>
				<CardActions disableSpacing>
					<Typography 
					expand={expanded3}
					onClick={handleExpandClick3}
					aria-expanded={expanded3} 
					sx={{ color: '#fff', marginLeft: '1rem', cursor: 'pointer' }} >
					{t('servicePrice')}
					</Typography>
					<ExpandMore
					expand={expanded3}
					onClick={handleExpandClick3}
					aria-expanded={expanded3}
					aria-label="Service Price"
					sx={{ color: '#fff' }}
					>
					<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
				<Collapse in={expanded3} timeout="auto" unmountOnExit>
					<CardContent>
					<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('price.choose')}
					</Typography>
					<ul style={{color: '#fff'}}>
						<li><strong>{t('price.fixedT')}</strong> {t('price.fixed')}</li>
						<br/>
						<li><strong>{t('price.monthT')}</strong> {t('price.month')}</li>
					</ul>
					<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('price.notSure')}
					</Typography>
					{/* <Button variant="outlined">Get an Invoice</Button> */}
					</CardContent>
					<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
						<Button onClick={handleButtonClick} variant="outlined">{t('getInvoice')}</Button>
					</div>
				</Collapse>
			</Card>
			<Card sx={{ width: { xs: '100%', sm: '45%' }, backgroundColor: '#1F2937' }}>
				<CardHeader
				sx={{ color: '#fff' }}
					title="Web Development"
				/>
				<CardMedia
					sx={{minWidth: '350px', height: '250px', objectFit: 'cover' }}
					component="img"
					src={img2}        
					alt="Web Development"
				/>
				<CardActions disableSpacing>
					<Typography 
					expand={expanded2}
					onClick={handleExpandClick2}
					aria-expanded={expanded2} 
					sx={{ color: '#fff', marginLeft: '1rem', cursor: 'pointer' }} >
					{t('serviceDetails')}
					</Typography>
					<ExpandMore
					expand={expanded2}
					onClick={handleExpandClick2}
					aria-expanded={expanded2}
					aria-label="Service Details"
					sx={{ color: '#fff' }}
					>
					<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
				<Collapse in={expanded2} timeout="auto" unmountOnExit>
					<CardContent>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('webDev.desc1')} 
						</Typography>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('webDev.contact1')} 
						</Typography>
						<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
							<Button onClick={handleButtonWeb} variant="outlined">{t('getInvoice')}</Button>
						</div>
						<Typography sx={{ color: '#fff', marginBottom: '1rem' }} variant="h4">{t('serviceDetails')} :</Typography>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('webDev.desc2')} 						
						</Typography>
						<ul style={{color: '#fff'}}>
						<li><strong>{t('webDev.FullT')} </strong> {t('webDev.Full')} </li>
						<br/>
						<li><strong>{t('webDev.RespT')} </strong> {t('webDev.Resp')} </li>
						<br/>
						<li><strong>{t('webDev.WebT')} </strong> {t('webDev.Web')} </li>
						<br/>
						<li><strong>{t('webDev.ApiT')} </strong> {t('webDev.Api')} </li>
						<br/>
						<li><strong>{t('webDev.DynaT')} </strong> {t('webDev.Dyna')} </li>
						<br/>
						<li><strong>{t('webDev.SEOT')} </strong> {t('webDev.SEO')} </li>
						<br/>
						<li><strong>{t('webDev.MaintT')} </strong> {t('webDev.Maint')} </li>
						</ul>
						<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('webDev.question')} 
						</Typography>
						<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
							<Button onClick={handleButtonWeb} variant="outlined">{t('getInvoice')}</Button>
						</div>
					</CardContent>
				</Collapse>
				<CardActions disableSpacing>
					<Typography 
					expand={expanded4}
					onClick={handleExpandClick4}
					aria-expanded={expanded4} 
					sx={{ color: '#fff', marginLeft: '1rem', cursor: 'pointer' }} >
					{t('servicePrice')}
					</Typography>
					<ExpandMore
					expand={expanded4}
					onClick={handleExpandClick4}
					aria-expanded={expanded4}
					aria-label="Service Price"
					sx={{ color: '#fff' }}
					>
					<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
				<Collapse in={expanded4} timeout="auto" unmountOnExit>
					<CardContent>
					<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('price.choose')}
					</Typography>
					<ul style={{color: '#fff'}}>
						<li><strong>{t('price.fixedT')}</strong> {t('price.fixed')}</li>
						<br/>
						<li><strong>{t('price.monthT')}</strong> {t('price.month')}</li>
					</ul>
					<Typography sx={{ color: '#fff', textAlign: "justify" }} paragraph>
						{t('price.notSure')}
					</Typography>
					{/* <Button variant="outlined">Get an Invoice</Button> */}
					</CardContent>
					<div style={{ display: 'flex',   justifyContent: 'center', marginBottom: '2rem'}}>
						<Button onClick={handleButtonWeb} variant="outlined">{t('getInvoice')}e</Button>
					</div>
				</Collapse>
			</Card>
		</div>
	</section>
  );
}
