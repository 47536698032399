// BlogPostTemplate.js
import React from 'react';

const BlogPostTemplate = ({ title, subtitle, content }) => {
  return (
    <div className="blog-post">
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      <div className="content">
        {Array.isArray(content) ? (
          content.map((block, index) => {
            if (block.type === "paragraph") {
              return <p key={index}>{block.text}</p>;
            } else if (block.type === "code") {
              return (
                <pre key={index}>
                  <code>{block.code}</code>
                </pre>
              );
            }
            return null;
          })
        ) : (
          <p>No content available</p>
        )}
      </div>
    </div>
  );
};

export default BlogPostTemplate;
