import React, { useState } from 'react';
import { Box, Button, Container, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Grid } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import emailjs from 'emailjs-com';

export default function Booking() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleBooking = () => {
    const templateParams = {
      name: name,
      phone: phone,
      date: selectedDate.format('MMMM D, YYYY'),
      to_email: 'mathieukohl@hotmail.com'
    };

    emailjs.send('service_3744rsn', 'template_8kmfm6h', templateParams, 'EAqcs3Q08RU2TlQjR')
        .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setOpenDialog(true);
      }, (error) => {
        console.error('FAILED...', error);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
      <Typography variant={isSmallScreen ? "h6" : "h4"} gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Réserver un appel gratuit
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar 
            value={selectedDate} 
            onChange={handleDateChange} 
            sx={{background: 'white', color: 'black', borderRadius: '8px'}}
          />
        </LocalizationProvider>
        <Grid sx={{mt:'10px', width: '320px'}} container spacing={2}>
        <Grid item xs={6} md={6}>
            <TextField sx={{ background: 'white', borderRadius: '5px', marginTop: '10px' }} required variant="filled" label="Nom" onChange={handleNameChange}/>
        </Grid>  
        <Grid item xs={6} md={6}>
            <TextField sx={{ background: 'white', borderRadius: '5px', marginTop: '10px' }} required variant="filled" label="Numéro de téléphone" type="phone" onChange={handlePhoneChange}/>
        </Grid>
        </Grid>
        <Button 
          variant="contained" 
          sx={{ mt: 3, backgroundImage: 'linear-gradient(90.21deg, #4A2FBD -5.91%, #AA367C 111.58%)' }} 
          onClick={handleBooking}
        >
          Confirmer la réservation
        </Button>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Réservation confirmée</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Votre appel a été réservé pour {selectedDate.format('MMMM D, YYYY')}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
