import React from 'react';
import meImg from "../assets/img/kohlmathieu.png"
import navIcon1 from "../assets/img/nav-icon3.svg";
import navIcon2 from "../assets/img/strava.svg";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; 

export const About = () => {
  const { t } = useTranslation();
  return (
    <section id="about">
      <div className="about-container">
        <div className="about-left-content">
          <h2>{t('aboutTitle')}</h2>
          <p className='about-paragraph'>
          {t('about')}
          </p>
          <div className="social-icon-footer">
              <a href="https://www.instagram.com/kohlmathieu/"><img src={navIcon1} alt="instagram" /></a>
              <a href="https://www.strava.com/athletes/80142114"><img src={navIcon2} alt="strava" /></a>
          </div>
        </div>
        <div className="about-right-content">
          <img src={meImg} alt="About Me" />
        </div>
      </div>
    </section>
  );
}


