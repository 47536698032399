import React from 'react';
import { Container, Typography, Grid } from '@mui/material';

const CodePenCollection = () => {
  // The pens in your collection (you might want to fetch this dynamically)
  const pens = [
    {
      title: "Pen 1",
      description: "Description of Pen 1",
      imageUrl: "https://www.google.com/imgres?q=reactjs%20show%20hide&imgurl=https%3A%2F%2Fassets.ccbp.in%2Ffrontend%2Fcontent%2Freact-js%2Fshow-hide-output.gif&imgrefurl=https%3A%2F%2Fgithub.com%2Fjamisaiteja%2FShow-Hide-App-React-JS&docid=qt4bVpFoSZNocM&tbnid=T99g3FtLeWQMLM&vet=12ahUKEwja6MPQj46HAxUSgP0HHdGRA2QQM3oECGkQAA..i&w=1007&h=961&hcb=2&ved=2ahUKEwja6MPQj46HAxUSgP0HHdGRA2QQM3oECGkQAA", // Placeholder image, replace with an actual thumbnail if available
      url: "https://codepen.io/Mathieu-Kohl/pen/MWMgeXy"
    },
    {
      title: "Pen 2",
      description: "Description of Pen 2",
      imageUrl: "https://via.placeholder.com/150", // Placeholder image, replace with an actual thumbnail if available
      url: "https://codepen.io/YOUR_USERNAME/pen/PEN_ID2"
    },
    {
      title: "Pen 3",
      description: "Description of Pen 3",
      imageUrl: "https://via.placeholder.com/150", // Placeholder image, replace with an actual thumbnail if available
      url: "https://codepen.io/YOUR_USERNAME/pen/PEN_ID3"
    }
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Ma Collection de Challenge ReactJs CodePen
      </Typography>
      <Grid container spacing={2}>
        {pens.map((pen, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <iframe
              height="300"
              style={{ width: '100%' }}
              scrolling="no"
              title={`codepen-${index}`}
              src={`${pen}/embed`}
              frameBorder="no"
              loading="lazy"
              allowTransparency="true"
              allowFullScreen="true"
            ></iframe>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CodePenCollection;
