
import julesDuc from "../assets/img/julesDuc.png";


export const Ecom = () => {

    return (
    <div className="gallery_body">
        <div className="gallery">
            <figure className="card">
                <a href="https://julesduc.ch/" target="_blank" rel="noreferrer">
                    <img
                        src={julesDuc}
                        alt="Jules Duc"
                    />
                </a>
            </figure>
        </div>
    </div>
    )
}