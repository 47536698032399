import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from 'react';
import { skills } from "../assets/data/data";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; 

export const Skills = () => {

    const { t } = useTranslation();

    return (
      <section id="skills">
        <div className="skills-container">
          <div className="skills-header">
            <ChipIcon className="skills-icon" />
            <h1 className="skills-title">
            {t('skills')} &amp; {t('Technologies')}
            </h1>
            <p className="skills-description">
            {t('skillsDesc')}
            </p>
          </div>
          <div className="skills-list" id="horizontal-scroll">
            {skills.map((skill, index) => (
              <div key={skill} className="skill-item">
                <div key={index} className="skill-card">
                  <BadgeCheckIcon className="skill-icon" />
                  <span className="skill-text">
                    {skill}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }